const imageslide = () => {
  const imageslide = $(".js_slider");
  if (imageslide.length > 0) {
    $.getScript("/js/lib/lory.min.js", () => {
      imageslide.each((i, el) => {
        if ($(el).find("li").length > 1) {
          createsmall(el);

          $(el).lory({
            enableMouseEvents: true,
            rewindOnResize: false,
          });
          el.addEventListener("after.lory.slide", (e) => slide(e, el));
        }
      });
    });
  }
};

const createsmall = (elem) => {
  const $elem = $(elem);
  const images = $elem.find("li");
  const $doter = $('<div class="doter white"></div>');

  images.each(() => {
    $('<span class="dot"></span>').appendTo($doter);
  });
  $doter.find(".dot").first().addClass("on");
  $doter.appendTo($elem);
};

const slide = (e, el) => {
  const $dots = $(el).find(".dot");
  $dots.removeClass("on");
  $($dots[e.detail.currentSlide]).addClass("on");
};

window.imageslide = imageslide;

export default imageslide;
