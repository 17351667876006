import debounce from "./debounce";

$.fn.blocslide = function (options) {
  const $this = this;
  let windowWidth = $(window).width();
  let position, $doter, $dots;

  const init = () => {
    const imageslide = $this.find(".slides");
    const contwidth = imageslide.width();
    const blocwidth = imageslide.find("li").width();
    const nbblocs = imageslide.find("li").length;
    const overwidth = blocwidth * nbblocs;

    if (overwidth > contwidth) {
      if ($this.find(".doter").length == 0) {
        $doter = $('<div class="doter dark"></div>');
        for (let index = 0; index < nbblocs; index++) {
          $doter.append('<span class="dot"></span>');
        }
        imageslide.css("justify-content", "flex-start");

        $dots = $doter.find(".dot");
        $dots.first().addClass("on");
        $dots.click((but) => {
          const getpos = $dots.index($(but.currentTarget));
          imageslide.animate({ scrollLeft: blocwidth * getpos + 1 }, 300);
        });
        imageslide.parent().append($doter);
      }

      imageslide.scroll(() => {
        const scleft = imageslide.scrollLeft();
        const pos = Math.floor(scleft / blocwidth);
        if (pos != position) {
          position = pos;
          $dots.removeClass("on");
          $($dots.get(pos)).addClass("on");
        }
        if (scleft + contwidth > overwidth - 1) {
          $dots.removeClass("on");
          $($dots.get(nbblocs - 1)).addClass("on");
          position = nbblocs - 1;
        }
      });
    } else {
      imageslide.css("justify-content", "center");
      if ($this.find(".doter").length > 0) {
        $this.find(".doter").remove();
      }
    }
  };

  $(window).resize(
    debounce(() => {
      const curWidth = $(window).width();
      if (curWidth !== windowWidth) {
        windowWidth = curWidth;
        init();
      }
    }, 200)
  );

  init();
};
