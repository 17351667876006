import cookie from "./cookie";
import debounce from "./debounce";

const banniere = () => {
  let windowWidth = $(window).width();
  const $baniere = $(".banniere");
  const $cont = $baniere.find(".contbanniere");
  const $texte = $baniere.find(".defile");

  const init = () => {
    if ($baniere.length > 0) {
      const check = cookie.get("banniere");
      if (!check) {
        $baniere.show();
        if ($texte.width() > $cont.width()) {
          $texte.addClass("disp");
        } else {
          $texte.removeClass("disp");
        }
        $baniere.find(".closeban").on("click", () => {
          cookie.set("banniere", true);
          $baniere.hide();
        });
      }
    }
  };

  $(window).resize(
    debounce(() => {
      const curWidth = $(window).width();
      if (curWidth !== windowWidth) {
        windowWidth = curWidth;
        init();
      }
    }, 200)
  );

  init();
};

export default banniere;
