import cookie from "./cookie";

var ouvert = 0;

window.OuvreAdmin = (url) => {
  $(".admin").addClass("actif");
  $("#administration").css({
    width: "calc(100% - 30px)",
  });
  $("#administration").css({
    transform: "translatex(150px)",
    "transition-property": "all",
  });
  $("#administration iframe").attr("src", url);
  $("body").css("overflow-y", "hidden");
  $("#administration iframe").css("overflow-y", "auto");
  ouvert = 1;
};

function afficheadmin(action, url) {
  if (action == 1) {
    window.OuvreAdmin(url);
  } else {
    window.FermeAdmin();
  }
}

window.FermeAdmin = () => {
  $(".admin").removeClass("actif");
  $("#administration").css({
    transform: "translatex(-150px)",
    width: "0px",
  });
  $("body").css("overflow-y", "show");
  $("#administration iframe").css("overflow-y", "hidden");
  $(".admin li").removeClass("actif");
  $("body").css("overflow-y", "auto");
  setTimeout(function () {
    $(".adminbouton").removeClass("actif").show(300);
  }, 300);
  ouvert = 0;
};

const cpadmin = () => {
  const check = cookie.get("compad_session");

  if (check) {
    const pageid = $("body").attr("data-id");
    const newsid = $("body").attr("data-newsid");
    const lang = $("html").attr("lang");
    $.ajax({
      type: "POST",
      url: "/checklo",
      data: { pageid: pageid, newsid: newsid, lang: lang },
      dataType: "json",
    }).done(function (data) {
      if (data.result) {
        $("head").append(
          '<link rel="stylesheet" href="/css/admin-module.min.css?rn=' +
            Math.random() +
            '" type="text/css" />'
        );
        const $adminctrl = $(data.result);
        $adminctrl.hide();
        $adminctrl.insertAfter($("header"));
        setTimeout(() => {
          $adminctrl.show();
        }, 400);
        $(".adminbouton").on("click", function () {
          $(this).toggleClass("actif");
          $(".admin").toggleClass("actif");
        });

        $(".admin a").on("click", function () {
          if (!$(this).children("li").hasClass("actif")) {
            var url = $(this).attr("href");
            afficheadmin(1, url);
            $(".admin li").removeClass("actif");
            $(this).children("li").addClass("actif");
            $(".adminbouton").hide(200);
          }
          return false;
        });
      }
    });
  }
};

export default cpadmin;
