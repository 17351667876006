const parallax = () => {
  const elem = $(".imgpara");
  if (elem.length > 0) {
    $.getScript("/js/lib/rellax.js", () => {
      elem.each((i, el) => {
        new Rellax(el, {
          speed: -3,
          center: !$(el).hasClass("notcenter")
          //     // wrapper: null,
          //     // round: true,
          //     // vertical: true,
          //     // horizontal: false
        });
      });
    });
  }
};

export default parallax;
