$.fn.slideloc = function (options) {
  return this.each(function () {
    $(this)
      .find(".background")
      .addClass("anime");
    const blocintrp = $(this).find(".blocint");
    const defileslintro = nb => {
      blocintrp.addClass("hid");
      setTimeout(() => {
        blocintrp.css("display", "none");
      }, 410);
      setTimeout(() => {
        $(blocintrp[nb]).css("display", "block");
        setTimeout(() => {
          $(blocintrp[nb]).removeClass("hid");
        }, 30);
      }, 450);
    };
    if (blocintrp.length > 1) {
      var nb = 1;
      setInterval(function () {
        defileslintro(nb);
        nb++;
        if (nb === blocintrp.length) {
          nb = 0;
        }
      }, 6000);
    }
  });
};
