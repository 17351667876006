$.fn.membres = function(options) {
  const main = $(this);
  const id = main.attr("data-id");
  const connect = main.find(".connect");
  const load = $('<div class="load"><img src="/icons/precharge.svg" width="40" height="40"  title="precharge" alt="precharge" ></div>');

  const container = main.parent();

  const $login = $(
    "<div>" +
      "<h3>Connexion espace membres</h3>" +
      '<div class="zone">' +
      '<input name="membre" type="password" placeholder="Mot de passe" class="logput" />' +
      '<a href="#" class="btn" data-bouton="white|grey|white|grey">Valider</a>' +
      "</div>" +
      '<div class="error"></div>' +
      "</div>"
  );

  const display = () => {
    const pass = localStorage.getItem("cpms");
    if (pass) {
      $.ajax({
        type: "POST",
        url: "/cmaccess",
        data: { get: id, ph: pass },
        dataType: "json"
      }).done(function(data) {
        if (data.result) {
          container.empty().html(data.result);
        } else {
          main.addClass("show");
        }
      });
    } else {
      main.addClass("show");
    }
  };

  const erreur = $login.find(".error");
  const bouton = $login.find(".btn");
  const input = $login.find("input[name=membre]");

  bouton.on("click", e => {
    e.stopPropagation();
    e.preventDefault();
    const mdp = input.get(0).value;

    load.insertAfter($login);
    $login.hide();

    $.ajax({
      type: "POST",
      url: "/cmaccess",
      data: { p: mdp },
      dataType: "json"
    }).done(function(data) {
      setTimeout(() => {
        if (data.result) {
          localStorage.setItem("cpms", data.result);
          display();
        } else {
          load.remove();
          $login.show();
          erreur.empty().text("Mot de passe incorrect !");
        }
      }, 800);
    });
  });

  connect.on("click", e => {
    e.stopPropagation();
    e.preventDefault();
    main.empty().append($login);
  });

  display();
};
