import cookie from "./cookie";
const rmtran = $(".bigphoto .photo").css("transition");

const darkmode = () => {
  const checkmode = $("body").attr("data-dark");
  if (checkmode == "on") {
    let timer;
    const changetheme = (theme) => {
      const cookiedark = cookie.get("theme");

      if (cookiedark) {
        theme = cookiedark;
      }

      $(".bigphoto .photo").css("transition", "none");

      if (theme == "dark") {
        $("html").addClass("dark");
        $("#zonelogo .logo").css(
          "background-image",
          "url(" + $(".logo").attr("data-srcdark") + ")"
        );
      } else {
        $("html").removeClass("dark");
        $("#zonelogo .logo").css(
          "background-image",
          "url(" + $(".logo").attr("data-src") + ")"
        );
      }
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        $(".bigphoto .photo").css("transition", rmtran);
      }, 100);
    };

    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      changetheme("dark");
    } else {
      changetheme("light");
    }
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .addEventListener("change", (e) => {
          changetheme(e.matches ? "dark" : "light");
        });
    }

    $(".darkbtn").on("click", (e) => {
      e.stopImmediatePropagation();
      e.preventDefault();
      const check = $("html").hasClass("dark");

      if (check) {
        cookie.set("theme", "light");
        changetheme("light");
      } else {
        cookie.set("theme", "dark");
        changetheme("dark");
      }
    });
  }
};

export default darkmode;
