import debounce from "./debounce";
import darkMode from "./darkmode"

const pannel = () => {
  initnav();
  let windowWidth = $(window).width();

  $(window).resize(
    debounce(() => {
      const curWidth = $(window).width();
      if (curWidth !== windowWidth) {
        windowWidth = curWidth;
        initnav();
      }
    }, 200)
  );
};

const animesouscat = () => {
  const $sousm = $(".sousm");
  $sousm.each((index, el) => {
    const $el = $(el);
    const haut = $el.find(".cadresousm").height() + 5;
    $el.height(0);
    const bouton = $el.parent().find(".menupri");
    bouton.on("click", () => {
      if ($el.height() == 0) {
        $(".menupri").removeClass("on");
        $sousm.height(0);
        $el.height(haut);
        bouton.addClass("on");
      } else {
        $el.height(0);
        bouton.removeClass("on");
      }
    });
  });
};

const togglenav = () => {
  const nav = $("header nav");
  const $sombre = $("#sombre");
  $("header .menusp")
    .off()
    .on("click", () => {
      nav.toggleClass("show");
      $sombre.toggleClass("show");

      if (nav.hasClass("show")) {
        $("body").addClass("disablenav");
      } else {
        $("body").removeClass("disablenav");
      }
    });

  $("header .menusp2 span")
    .off()
    .on("click", () => {
      nav.toggleClass("show");
      $sombre.toggleClass("show");
      if (nav.hasClass("show")) {
        $("body").addClass("disablenav");
      } else {
        $("body").removeClass("disablenav");
      }
    });
  $sombre.off().on("click", () => {
    nav.removeClass("show");
    $sombre.removeClass("show");
    $("body").removeClass("disablenav");
  });
};

const initnav = () => {
  const nav = $("header nav");
  if ($(window).width() < 1024) {
    $("header nav").remove();
    nav.appendTo($("header"));
    togglenav();
    animesouscat();
  } else {
    $("header nav").remove();
    setTimeout(() => {
      $(".sousm").height("auto");
    }, 300);
    nav.appendTo($(".headbottom .globalcont"));
  }
  darkMode();

};

export default pannel;
