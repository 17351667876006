import debounce from "./debounce";

$.fn.slideshow = function (options) {
  const $this = this;
  const imageslide = $this.find(".slideshow");
  const vitesse = imageslide.attr("data-vitesse");
  let timer = false;
  const nbslide = $this.find(".slideshowtxt");

  if (imageslide.length > 0) {
    $.getScript("/js/lib/lory.min.js", () => {
      imageslide[0].addEventListener("before.lory.slide", sliding);
      imageslide[0].addEventListener("after.lory.init", lazyimages);
      imageslide[0].addEventListener("after.lory.slide", (e) =>
        slide(e, imageslide[0])
      );

      createsmall();
      imageslide.lory({
        infinite: nbslide.length == 1 ? 0 : 1,
        enableMouseEvents: true,
        rewindOnResize: false,
      });

    });
  }

  const createsmall = () => {
    const images = imageslide.find("li");
    const $doter = $('<div class="doter white"></div>');

    images.each(() => {
      $('<span class="dot"></span>').appendTo($doter);
    });
    $doter.find(".dot").first().addClass("on");
    $doter.appendTo(imageslide);
  };

  const sliding = (el) => {
    if (nbslide.length > 1) imageslide.find(".slideshowtxt").removeClass("on");
  };

  const slide = (e, el) => {
    if (nbslide.length > 1) {
      const $dots = $(el).find(".dot");
      $dots.removeClass("on");
      const index = e.detail.currentSlide - 1;
      $($dots[index]).addClass("on");
      textes(index, el);
      if (timer) clearTimeout(timer);
      loopslide(vitesse);
    }
  };

  const textes = (index, el) => {
    const $textes = $(el).find(".slideshowtxt");
    $($textes[index]).addClass("on");
  };

  const loopslide = (speed) => {
    timer = setTimeout(() => {
      imageslide.data().lory.next();
    }, speed);
  };

  const lazyimages = () => {
    imageslide.find(".js_slide").each((i, el) => {
      if (i == 0) {
        $(el).lazyimg(() => {
          setTimeout(() => {
            imageslide.data().lory.slideTo(0);
            loopslide(vitesse);
          }, 100)



        });
      } else {
        $(el).lazyimg();
      }
    });
  };
};
