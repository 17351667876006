$.fn.slidecont = function(options) {
  const $this = this;
  let timer = false;
  let width = $(window).width();
  let speed = width > 1023 ? 300 : 0;

  $this
    .find(".choixsl")
    .off()
    .on("click", e => {
      const index = $this.find(".choixsl").index(e.currentTarget);
      changemenu(index);
    });

  const changemenu = index => {
    const $dot = $this.find(".dot");
    const $titres = $this.find(".slidetitre");
    const $photos = $this.find(".slidephts");
    const $textes = $this.find(".textslide ");

    $dot.removeClass("active");
    $titres.removeClass("active");

    $($dot.get(index)).addClass("active");
    $($titres.get(index)).addClass("active");

    $photos.addClass("cacher");
    $textes.addClass("cacher");

    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      $($photos.get(index)).removeClass("cacher");
      $($textes.get(index)).removeClass("cacher");
    }, speed);
  };
};
