import debounce from "./debounce";

$.fn.tabs = function(options) {
  const main = $(this);
  const $li = main.find(".titre li");
  const $cursor = main.find(".titre-cont .cursor");
  let width = $($li[0]).width();
  const $bloc = main.find(".contents .bloc");
  const blocheight = main.find(".courses");

  $cursor.width(width);

  $li.off().on("click", e => {
    const select = $(e.currentTarget);
    const n = $li.index(select);
    move_cursor(n);
    move_bloc(n);
  });

  const move_cursor = n => {
    const move_width = n * width;
    $cursor.css("transform", "translateX(" + move_width + "px)");
    $li.removeClass("on");
    $($li[n]).addClass("on");
  };

  const move_bloc = n => {
    $bloc.css({ "z-index": 0, opacity: 0 });
    $($bloc[n]).css({ "z-index": 1, opacity: 1 });

    const height = $($bloc[n]).height();

    if ($(window).width() > 600) {
      if (height > 500) {
        blocheight.height(height);
      } else {
        blocheight.height(500);
      }
    }
  };
  $(window).resize(
    debounce(() => {
      width = $($li[0]).width();
      $cursor.width(width);
    }, 200)
  );
};
