const headerMenuScroll = () => {
  const $headermenu = $(".headbottom");
  if ($headermenu.length == 0) return;
  const headerpos = $headermenu.position().top;
  const postop = $headermenu.position().top + $headermenu.height();
  let scrollpos = 0;
  let animation = false;

  $(window).scroll(() => {
    const windowScroll = $(window).scrollTop();

    if (windowScroll > postop) {
      if (!$headermenu.hasClass("on")) {
        $headermenu.addClass("on");

        if (animation) clearTimeout(animation);

        animation = setTimeout(() => {
          $headermenu.addClass("anime");
        }, 310);
      }
    } else if (windowScroll < headerpos) {
      if ($headermenu.hasClass("on")) {
        $headermenu.removeClass("on show anime");
      }
    }
    if ($headermenu.hasClass("on") && windowScroll < scrollpos) {
      $headermenu.addClass("show");
    }
    if ($headermenu.hasClass("on anime show") && windowScroll > scrollpos) {
      $headermenu.removeClass("show");
    }
    scrollpos = windowScroll;
  });
};

export default headerMenuScroll;
