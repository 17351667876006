import supportsWebP from "supports-webp";

$.fn.photozoom = function (options) {
  const $this = this;
  $('<div class="zoomi"><i class="icon-zoom-in"></i></div>').prependTo($this);
  const zoomcont = $this.hasClass("phzoom") ? $this : $this.find(".phzoom");
  const pswpElement = $(".pswp")[0];
  const $zoomi = $this.find(".zoomi");


  const openslide = () => {
    const swoptions = {
      index: 0,
      focus: false,
      history: false,
      showAnimationDuration: 0,
      hideAnimationDuration: 0,
    };



    if (zoomcont.length > 0) {
      $.when(
        $.getScript("/js/lib/photoswipe.min.js"),
        $.getScript("/js/lib/photoswipe-ui-default.min.js")
      ).done(function () {
        const slides = [];

        zoomcont.each((index, el) => {
          const image = supportsWebP
            ? $(el).attr("data-srcbgwp")
            : $(el).attr("data-srcbg");
          const imgsize = $(el).attr("data-imgsize").split("x");
          const legende = $(el).attr("data-legende");

          slides.push({
            src: image,
            w: imgsize[0],
            h: imgsize[1],
            title: legende,
          });
        });

        const gallery = new PhotoSwipe(
          pswpElement,
          PhotoSwipeUI_Default,
          slides,
          swoptions
        );
        gallery.options.shareEl = false;
        gallery.options.fullscreenEl = false;
        gallery.options.fclickToCloseNonZoomable = false;
        gallery.options.tapToClose = false;
        gallery.options.tapToToggleControls = false;
        gallery.init();
        $('.pswp__button--close').on('pswpTap', function (event) {
          event.stopPropagation();
        });
      });
    }
  };
  $zoomi.on("click", () => {
    openslide();
  });
};
