$.fn.news = function (options) {
  const $main = $(this);
  const catego = $main.attr("data-cat");
  const $suitre = $main.find(".newctn");
  const $suitebtn = $main.find(".newtpage");
  const $more = $('<i class="icon-down-open"></i>');
  const $load = $('<img src="/icons/precharge.svg">');
  let page = 0;
  const template =
    '<a href=""><div class="articont lazy lazyimg"><div class="artiphoto precharge"></div><div class="artitext"><h4>TITRE</h4><span></span><div class="infonews"></div></div></div></a>';

  if ($main.find(".artelft").length > 0) {
    $.ajax({
      type: "POST",
      url: "/api/news",
      data: { page: "all", catego: catego },
      dataType: "json",
    }).done(function (data) {
      if (data.total > 10) {
        $suitebtn.append($more);
      }
    });

    $suitebtn.on("click", () => {
      getmore();
    });
  }

  const getmore = () => {
    page++;
    $suitebtn.empty().append($load);
    $.ajax({
      type: "POST",
      url: "/api/news",
      data: { page: page, catego: catego },
      dataType: "json",
    }).done(function (data) {
      if (data.number > 0) {
        $suitebtn.empty().append($more);

        data.news.forEach((actu) => {
          const newtplt = $(template);

          newtplt.attr("href", actu.url);
          newtplt.find("h4").text(actu.titre);
          newtplt.find("span").text(actu.intro);
          newtplt.find(".infonews").text("Posté " + actu.date);
          const vignette = newtplt.find(".artiphoto");
          if (actu.photo.src) {
            vignette.attr("data-src", actu.photo.src);
          }
          if (actu.photo.jpg) {
            vignette.attr("data-srcsm", actu.photo.jpg.small);
            vignette.attr("data-srcbg", actu.photo.jpg.small);
          }
          if (actu.photo.webp) {
            vignette.attr("data-srcsmwp", actu.photo.webp.small);
            vignette.attr("data-srcbgwp", actu.photo.webp.small);
          }
          newtplt.appendTo($suitre);
          newtplt.find(".artiphoto").lazyimg();
        });
        if (data.number < 9) {
          $suitebtn.hide();
        }
      }
    });
  };
};
