import scrollspy from "scrollspy";

const lazyscroll = () => {
  $(".lazy").each(function () {
    const me = this;
    const $me = $(me);
    scrollspy.add(me, {
      scrollIn: () => {
        if (!$me.hasClass("lazyshow")) {
          $me.addClass("lazyshow");

          if ($me.hasClass("lazyimg")) {
            $me.find(".precharge").each((i, el) => {
              $(el).lazyimg();
            });
          }

          if ($me.hasClass("photozoom")) {
            $me.photozoom();
          }

          if ($me.attr("data-lazy")) {
            if ($me.attr("data-jp")) {
              $me[$me.attr("data-lazy")]();
            } else {
              const fn = window[$me.attr("data-lazy")];
              if (typeof fn === "function") fn($me);
            }
          }
        }
      }
    });
  });
};

export default lazyscroll;
