/**
* jQuery.getScripts v1.0.1
* https://github.com/hudsonfoo/jquery-getscripts
*
* Load one or multiple JavaScript files from the server using a GET HTTP request, then execute them..
*
* Copyright 2014 David Hudson
*
* Released Under the Following Licenses
* https://github.com/hudsonfoo/jquery-getscripts/blob/1.0.0/MIT-LICENSE.txt
*
* Date: 2014-03-23T21:35:00
*/
!function(a){"use strict";a.getScripts||(a.getScripts=function(b){var c,d,e,f;if(c=a.extend({async:!1,cache:!0},b),"string"==typeof c.urls&&(c.urls=[c.urls]),f=[],d=function(){a.ajax({url:c.urls.shift(),dataType:"script",cache:c.cache,success:function(){f.push(arguments),c.urls.length>0?d():"function"==typeof b.success&&b.success(a.merge([],f))}})},e=function(){f.push(arguments),f.length===c.urls.length&&"function"==typeof b.success&&b.success(a.merge([],f))},c.async===!0)for(var g=0;g<c.urls.length;g++)a.ajax({url:c.urls[g],dataType:"script",cache:c.cache,success:e});else d()})}(jQuery);