const infopop = () => {
  $(".infopop").each(function () {
    var popup = $(this);
    popup.find("a").on("click", function () {
      var cont = popup.find(".txtpop").html();
      var render = $(
        '<div class="popuplay"><section class="popup" style="display:block;opacity:1;"><div class="contpop"><div class="ferme"><i class="icon-cancel"></i></div>' +
          cont +
          "</div></section></div>"
      ).appendTo("body");
      setTimeout(function () {
        render.addClass("actif");
        render.find(".ferme").on("click", function () {
          render.removeClass("actif");
          setTimeout(function () {
            render.remove();
          }, 300);
        });
      }, 50);
    });
  });
};

export default infopop;
