import debounce from "./debounce";

const footerfixed = () => {
  checksize();
  $(window).resize(
    debounce(() => {
      checksize();
    }, 200)
  );
};

const checksize = () => {
  const height = $(window).height();
  const footerheight = $(".bottomfix").height();

  if (footerheight < height - 20) {
    init(true, footerheight);
  } else {
    init();
  }
};

const init = (fixed = false, height = 0) => {
  if (fixed) {
    $(".footer").css("margin-bottom", height);
    $(".bottomfix").addClass("on");
  } else {
    $(".footer").css("margin-bottom", 0);
    $(".bottomfix").removeClass("on");
  }
};

export default footerfixed;
