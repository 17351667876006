import "./libs/jquery.getscripts.min";
import "./libs/lazyimg";
import "./libs/slideshow";
import "./libs/slidecont";
import "./libs/slideloc";
import "./libs/googlerates";
import "./libs/videos";
import "./libs/tabs";
import "./libs/newsletter";
import "./libs/membres";
import "./libs/googlemap";
import "./libs/googlemaplarge";
import "./libs/contact";
import "./libs/photosec";
import "./libs/cardslide";
import "./libs/blocslide";
import "./libs/photozoom";
import "./libs/news";

import imageslide from "./libs/imageslide";
import banniere from "./libs/banniere";
import accordeon from "./libs/accordeon";
import skilocdate from "./libs/skilocdate";
import parallax from "./libs/parallax";
import bouton from "./libs/bouton";
import lazyscroll from "./libs/lazyscroll";
import conditions from "./libs/conditions";
import pannel from "./libs/pannel";
import headerMenuScroll from "./libs/headermenuSroll";
import footerfixed from "./libs/footerfixed";
import widgetsfn from "./libs/widgets";
import scrollToTop from "./libs/scrollTop";
import ancresFn from "./libs/ancres";
import infopop from "./libs/infopop";
import cpadmin from "./libs/cpadmin";
import darkMode from "./libs/darkmode";

document.addEventListener(
  "DOMContentLoaded",
  function (event) {
    $.ajaxSetup({
      cache: true,
    });
    darkMode();
    headerMenuScroll();
    skilocdate();
    imageslide();
    banniere();
    bouton();
    parallax();
    lazyscroll();
    conditions();
    pannel();
    footerfixed();
    accordeon();
    widgetsfn();
    scrollToTop();
    ancresFn();
    infopop();
    cpadmin();
    if (typeof gaTracker == "function") {
      gaTracker();
    }
  },
  { passive: true }
);
