const scrollToTop = () => {
  const $div = $(".upscrollbtn");
  $div.click(function() {
    $("html,body").animate({ scrollTop: 0 }, "slow");
  });
  $(window).scroll(function() {
    if ($(window).scrollTop() < 500) {
      if ($div.hasClass("on")) $div.removeClass("on");
    } else {
      if (!$div.hasClass("on")) $div.addClass("on");
    }
  });
};
export default scrollToTop;
