import datepicker from "./datepicker";

const skilocdate = () => {
  const lang = $(".skilocation").attr("data-lang");
  const datevelo = $(".skilocation").attr("data-caloneday");

  let datepostinit;
  var skidate1 = $(".slslide .contdate");
  var skidate2 = $("section.dates");

  if (skidate2.length > 0) {
    skidate2.before('<span class="testdate"></span>');
  }

  var testdate = $(".testdate");

  if (skidate1.length > 0 || skidate2.length > 0) {
    datepicker(lang);
  }

  if ($(window).width() < 500) {
    var heightda = skidate2.find(".contenu").height();
    var minibtn = $('<div class="minibtn"></div>');
    minibtn.on("click", function () {
      if (skidate2.hasClass("displ")) {
        skidate2.removeClass("displ");
        skidate2.height(0);
      } else {
        skidate2.addClass("displ");
        skidate2.height(heightda);
      }
    });
    skidate2.before(minibtn);
  }


};

export default skilocdate;
