import debounce from "./debounce";

$.fn.photosec = function(options) {
  const $this = this;
  let windowWidth = $(window).width();

  const init = $this => {
    const $more = $this.find(".more");
    const $cont = $this.find(".photxt");
    const $para = $this.find("p");

    if ($para.height() > $cont.height()) $more.addClass("on");

    $more.off().on("click", () => {
      $this.toggleClass("show");
      $cont.height($this.hasClass("show") ? $para.height() : 140);
    });
  };

  $(window).resize(
    debounce(() => {
      const curWidth = $(window).width();
      if (curWidth !== windowWidth) {
        windowWidth = curWidth;
        init($this);
      }
    }, 200)
  );
  return init($this);
};
