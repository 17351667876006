const preload = (arr) => {
	let newimages = [];
	let loadedimages = 0;
	let postaction = () => {};
	arr = typeof arr !== 'object' ? [ arr ] : arr;
	const imageloadpost = () => {
		loadedimages++;
		if (loadedimages === arr.length) {
			postaction(newimages);
		}
	}
	for (var i = 0; i < arr.length; i++) {
		newimages[i] = new Image();
		newimages[i].src = arr[i];
		newimages[i].onload = function() {
			imageloadpost();
		};
		newimages[i].onerror = function() {
			imageloadpost();
		};
	}
	return {
		done: (f) => {
			postaction = f || postaction;
		}
	};
};

export default preload