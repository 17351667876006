$.fn.newsletter = function(options) {
  const main = $(this);
  const input = main.find("input[name=newsletter]");
  const button = main.find(".newsltbtn");
  const error = main.find(".error");
  const contain = main.find("span");
  const load = $('<div class="load"><img src="/icons/precharge.svg" width="40" height="40"  title="precharge" alt="precharge" ></div>');

  function ValidateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  }

  const ajouter = email => {
    contain.hide();
    load.insertAfter(contain);
    $.ajax({
      type: "POST",
      url: "/newsltradd",
      data: { mail: email },
      dataType: "json"
    }).done(function(data) {
      setTimeout(() => {
        if (data.error) {
          error.empty().text(data.error);
          load.remove();
          contain.show();
        } else {
          load.remove();
          contain
            .empty()
            .html(
              '<div class="validation"><i class="icon-paper-plane-empty"></i>Votre demande d\'inscription a bien été enregistrée ! Merci</div>'
            )
            .show();
        }
      }, 900);
    });
  };

  button.on("click", e => {
    e.stopPropagation();
    e.preventDefault();
    if (ValidateEmail(input.get(0).value)) {
      error.empty();
      ajouter(input.get(0).value);
    } else {
      error.empty().text("Merci d'indiquer une adresse email valide !");
    }
  });
};
