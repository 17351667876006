import cookie from "./cookie";

const conditions = () => {
  const check = cookie.get("cookies");
  if (!check) {
    $(".acceptcookies").removeClass("hide");
    accept();
  } else {
    initservices();
  }
};

const accept = () => {
  $(".acceptcookies .btnwhi")
    .off()
    .on("click", (e) => {
      e.preventDefault();
      const allcookies = {};
      $(".acceptcookies input").each((index, el) => {
        allcookies[el.name] = el.checked;
      });
      cookie.set("cookies", JSON.stringify(allcookies), 120);
      $(".acceptcookies").addClass("hide");
      initservices();
    });

  $(".acceptcookies .cookreject")
    .off()
    .on("click", (e) => {
      e.preventDefault();
      const allcookies = {};
      $(".acceptcookies input").each((index, el) => {
        allcookies[el.name] = false;
      });
      cookie.set("cookies", JSON.stringify(allcookies), 120);
      $(".acceptcookies").addClass("hide");
      initservices();
    });
};

const initservices = () => {
  const cookservices = JSON.parse(cookie.get("cookies"));

  if (cookservices.cookperf) {
    const ganacode = $("header").attr("data-ganalytics");
    $.getScript("//www.googletagmanager.com/gtag/js?id=" + ganacode);
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    window.gtag = gtag;

    window.gtag("js", new Date());
    window.gtag("config", ganacode);
  }

  if (cookservices.cookpub) {
    const fbcode = $("header").attr("data-fbpixel");
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      "script",
      "https://connect.facebook.net/en_US/fbevents.js"
    );
    fbq("init", fbcode);
    fbq("track", "PageView");
  }
};

export default conditions;
