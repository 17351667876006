$.fn.googlemap = function (options) {
  const $main = $(this);
  const zoom = $main.attr("data-zoom");
  const key = $main.attr("data-key");
  const gps = $main.attr("data-gps");
  const $map = $main.find(".map");
  const $mapnav = $main.find(".mapnav");

  $.getScript(
    "https://maps.googleapis.com/maps/api/js?v=3.exp&key=" + key,
    () => {
      var styles = [
        {
          stylers: [{ hue: "#587591" }],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [{ lightness: 100 }, { visibility: "simplified" }],
        },
        {
          featureType: "road",
          elementType: "labels",
        },
        {
          featureType: "poi",
          stylers: [{ visibility: "off" }],
        },
      ];

      var styledMap = new google.maps.StyledMapType(styles, {
        name: "Styled Map",
      });
      var bounds = new google.maps.LatLngBounds();
      var map = new google.maps.Map($map[0], {
        center: new google.maps.LatLng(46.4430444, 4.0849144),
        mapTypeControlOptions: {
          mapTypeIds: [google.maps.MapTypeId.ROADMAP, "map_style"],
        },
        zoom: Number(zoom),
        scaleControl: false,
        scrollwheel: false,
        // draggable: false,
        // disableDoubleClickZoom: true,
        panControl: false,
        mapTypeControl: false,
        mapTypeId: "roadmap",
      });

      map.mapTypes.set("map_style", styledMap);
      map.setMapTypeId("map_style");

      function centrevu() {
        var markers = [];
        $mapnav.find(".lieu").each(function (index) {
          var id = $(this).attr("data-pos");
          var nom = $(this).attr("data-nom");
          var logo = $(this).attr("data-logo");
          var logosize = $(this).attr("data-logosize").split("|");
          var posibase = $(this).attr("data-position").split(",");
          var position = new google.maps.LatLng(posibase[0], posibase[1]);
          bounds.extend(position);

          if (logo) {
            var icon = {
              url: logo, // url
              scaledSize: new google.maps.Size(
                Number(logosize[0]),
                Number(logosize[1])
              ), // size
              origin: new google.maps.Point(0, 0), // origin
              labelOrigin: new google.maps.Point(
                Number(logosize[0]),
                Number(logosize[1]) + 15
              ),
              anchor: new google.maps.Point(
                Number(logosize[0]),
                Number(logosize[1])
              ),
            };
          } else {
            icon = "/icons/marker.png";
          }

          markers[index] = new google.maps.Marker({
            position: position,
            map: map,
            label: {
              text: nom,
              color: "black",
              fontWeight: "bold",
            },
            id: id,
            icon: icon,
            animation: google.maps.Animation.DROP,
          });

          google.maps.event.addListener(
            markers[index],
            "click",
            function () {
              map.setCenter(markers[index].position);
              map.setZoom(18);
            },
            { passive: true }
          );

          google.maps.event.addListener(
            markers[index],
            "mouseover",
            function () {
              $($mapnav.find(".lieu")[index]).addClass("on");
            },
            { passive: true }
          );

          google.maps.event.addListener(
            markers[index],
            "mouseout",
            function () {
              $($mapnav.find(".lieu")[index]).removeClass("on");
            },
            { passive: true }
          );
        });

        var listener = google.maps.event.addListener(map, "idle", function () {
          if (zoom && map.getZoom() > zoom) map.setZoom(Number(zoom));
          google.maps.event.removeListener(listener);
        });

        var text = $(this).find(".text").val();
        $main.find(".gm-style-iw").next("div").hide();

        if ($main.find(".lieu").length === 1) {
          map.setCenter(markers[0].position);
        } else {
          map.fitBounds(bounds);
        }
        const handlerIn = (e) => {
          const pos = $(e.currentTarget).attr("data-pos");
          markers[pos].setAnimation(google.maps.Animation.BOUNCE);
        };
        const handlerOut = (e) => {
          const pos = $(e.currentTarget).attr("data-pos");
          markers[pos].setAnimation(null);
        };
        const handlerClick = (e) => {
          const pos = $(e.currentTarget).attr("data-pos");
          map.setCenter(markers[pos].position);
          map.setZoom(18);
        };

        $mapnav.find(".lieu").on({
          mouseenter: handlerIn,
          mouseleave: handlerOut,
          click: handlerClick,
        });

        $mapnav
          .find(".icon-nav")
          .off()
          .on("click", function () {
            if ($mapnav.hasClass("on")) {
              $mapnav.removeClass("on");
            } else {
              $mapnav.addClass("on");
            }
          });
      }

      if ($main.find(".lieu").length > 1) {
        var globalvue =
          '<div class="globalvue"><i class="icon-zoom-out"></i></div>';
        $map.append(globalvue);

        $main.find(".globalvue").on("click", function () {
          map.fitBounds(bounds);
        });
      }

      if ($(window).width() < 801) {
        $mapnav.addClass("on");
      }

      centrevu();
    }
  );
};
