$.fn.googlerates = function (options) {
  const main = $(this);
  if (main.length > 0) {
    const key = main.attr("data-key");
    const place = main.attr("data-place");

    $.when(
      $.getScript(
        "https://maps.googleapis.com/maps/api/js?v=3.exp&key=" +
          key +
          "&libraries=places"
      ),
      $.getScript("/js/dist/grate.js"),
      $.Deferred(function (deferred) {
        $(deferred.resolve);
      })
    ).done(() => {
      main.googlePlaces({
        placeId: place,
        render: ["reviews"],
        min_rating: 3,
        max_rows: 5,
        rotateTime: false,
        onComplete: (reviews) => {
          renderReviews(reviews);
        },
      });
    });
  }

  const convertTime = (UNIX_timestamp) => {
    var a = new Date(UNIX_timestamp * 1000);
    var months = [
      "Jan",
      "Fév",
      "Mar",
      "Avr",
      "Mai",
      "Jun",
      "Jul",
      "Aoû",
      "Sep",
      "Oct",
      "Nov",
      "Déc",
    ];
    var time =
      a.getDate() + " " + months[a.getMonth()] + ". " + a.getFullYear();
    return time;
  };

  const renderReviews = (re) => {
    re.map((el) => {
      let $bloc = $(
        '<div class="review"><div class="head1"><span class="avatar"></span><div><span class="name"></span><span class="date"></span></div><div class="rate"><span class="star"></span></div></div><div class="descrip"><span></span></div><div class="picg"><img src="https://www.gstatic.com/verifiedreviews/fr/gcr_logo_stacked.png" title="avis google" /><span class="icofleche readmor"></span></div></div>'
      );
      $bloc
        .find(".avatar")
        .css("background-image", "url(" + el.profile_photo_url + ")");
      $bloc.find(".name").text(el.author_name);
      $bloc.find(".date").text(convertTime(el.time));
      $bloc.find(".descrip span").text(el.text);
      $bloc.find(".star").css("width", Number(el.rating * 14) + "px");
      $bloc.appendTo(main);
    });

    main.find(".descrip span").each((i, el) => {
      if ($(el).height() < $(el).parent().height()) {
        $(el).parent().parent().find(".readmor").hide();
      }
    });

    main.find(".readmor").map((i, el) => {
      const $this = $(el);
      $this.off().on("click", (e) => {
        if ($(el).hasClass("active")) {
          $(el).removeClass("active");
          $this.parent().parent().find(".descrip").css("height", "90px");
        } else {
          $(el).addClass("active");
          $this.parent().parent().find(".descrip").css("height", "auto");
        }
      });
    });
  };
};
