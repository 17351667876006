import debounce from "./debounce";

$.fn.cardslide = function (options) {
  const imageslide = this[0];
  let windowWidth = $(window).width();

  const init = () => {
    if ($(window).width() < 1024) {
      $.getScript("/js/lib/lory.min.js", () => {
        createsmall(imageslide);

        $(imageslide).lory({
          rewindOnResize: false,
          slidesToScroll: 1,
          forceIndexUpdate: true
        });
        imageslide.addEventListener("after.lory.slide", e =>
          slide(e, imageslide)
        );
      });
    } else {
      if ($(imageslide).lory) {
        $(imageslide).data().lory.slideTo(0);
        $(imageslide).find("div.doter").remove();
      }
    }
  };

  const createsmall = elem => {
    const $elem = $(elem);
    const images = $elem.find("li");
    const $doter = $('<div class="doter dark"></div>');

    images.each(() => {
      $('<span class="dot"></span>').appendTo($doter);
    });
    $doter
      .find(".dot")
      .first()
      .addClass("on");
    $doter.appendTo($elem);

    $($elem.find("div.cont")[0]).addClass("on");
  };

  const slide = (e, el) => {
    const $dots = $(el).find(".dot");
    $dots.removeClass("on");
    $(el)
      .find("div.cont")
      .removeClass("on");
    $($dots[e.detail.currentSlide]).addClass("on");
    $($(el).find("div.cont")[e.detail.currentSlide]).addClass("on");
  };

  $(window).resize(
    debounce(() => {
      const curWidth = $(window).width();
      if (curWidth !== windowWidth) {
        windowWidth = curWidth;
        init();

      }
    }, 200)
  );

  init();

}
