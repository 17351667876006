window.bouttonAnim = (elem) => {
  const $this = $(elem);
  if (!$this.hasClass("actif")) {
    $this.addClass("actif");
    var height = $this.css("height");
    if ($this.attr("data-bouton")) {
      var bt_pro = $this.attr("data-bouton").split("|");
    }
    var bt_text = $this.html();
    var boutonl = $(
      '<div class="textbout">' +
        bt_text +
        "</div>" +
        '<div class="intbout">' +
        bt_text +
        "</div>"
    );
    var bouton = $this.empty().append(boutonl);
    if ($this.attr("data-bouton")) {
      bouton
        .find(".textbout")
        .css({ "background-color": bt_pro[0], color: bt_pro[1] });
      bouton
        .find(".intbout")
        .css({ "background-color": bt_pro[2], color: bt_pro[3] });
    }
  }
};

const bouton = () => {
  const body = $("body").attr("data-btnef");
  if (body) {
    $("body")
      .find(".esp")
      .each(function () {
        window.bouttonAnim(this);
      });
  }
};

export default bouton;
