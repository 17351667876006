$.fn.videos = function(options) {
  const main = $(this);
  const player = main.find("#iframyt");
  const video = main.find(".video");

  video.on("click", ev => {
    const id = $(ev.currentTarget).attr("data-id");
    player.attr("src", "https://www.youtube.com/embed/" + id + "?autoplay=1");
  });
};
