const ancresFn = () => {
  let currentHash = "#debut";
  $(window).scroll(function () {
    $(".ancre").each(function () {
      var t = window.pageYOffset - $(this).offset().top,
        e = $(this).attr("id");
      t < 30 &&
        t > -30 &&
        currentHash != e &&
        (history.pushState("", e, "#" + e), (currentHash = e));
    });
  });
};

export default ancresFn;
