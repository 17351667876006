import debounce from "./debounce";

const deplacewd = () => {
  const width = $(window).width();
  const $topleft = $(".topleft");

  if (width < 600) {
    if (!$topleft.hasClass("sma")) {
      $topleft.addClass("sma");
      $topleft.detach();
      $(".topm")
        .parent()
        .append($topleft);
    }
  } else {
    if ($topleft.hasClass("sma")) {
      $topleft.removeClass("sma");
      $topleft.detach().appendTo(".topb");
    }
  }
};

const widgetsfn = () => {
  deplacewd();
  $(window).resize(
    debounce(() => {
      deplacewd();
    }, 200)
  );
};

export default widgetsfn;
