const accordeon = () => {
  const speed = 200;
  $(".accordeon ").each((i, el) => {
    const $el = $(el).find("h4");
    $el.append('<div class="fleche"><i class="icon-down-open"></i></div>');

    $el
      .first()
      .find(".fleche")
      .addClass("on");

    $el
      .first()
      .next()
      .addClass("on")
      .slideDown(speed);

    $el.off().on("click", e => {
      const elem = $(e.currentTarget).next();

      if (!elem.hasClass("on")) {
        $el
          .next()
          .removeClass("on")
          .slideUp(speed);
        elem.addClass("on").slideDown(speed);
        $el.find(".fleche").removeClass("on");
        $(e.currentTarget)
          .find(".fleche")
          .addClass("on");
      }
      return false;
    });
  });
};

export default accordeon;
